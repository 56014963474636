/** @format */

import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Base from "./components/Layouts/Base";
import { ParallaxProvider } from "react-scroll-parallax";
import Farming from "./pages/Farming";
import WhitePaper from "./pages/WhitePaper";
import ReactGA from "react-ga";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet } from "wagmi/chains";
ReactGA.initialize("G-2RS9PBT60W");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const chains = [mainnet];
  const projectId = "1c200fb2c2e182bbda9170cd52450702";

  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
  });
  const ethereumClient = new EthereumClient(wagmiConfig, chains);

  return (
    <div className='App'>
      <div className='custom-cursor' />
      <WagmiConfig config={wagmiConfig}>
        <BrowserRouter>
          <ParallaxProvider>
            {/* <Base> */}
            <Routes>
              <Route index path='/' element={<Home />} />
              <Route path='/farming' element={<Farming />} />
              <Route path='/whitepaper' element={<WhitePaper />} />
            </Routes>
            {/* </Base> */}
          </ParallaxProvider>
        </BrowserRouter>
        <div className=' z-3  position-absolute'>
          <Web3Modal
            projectId={projectId}
            ethereumClient={ethereumClient}
            defaultChain={1}
          />
        </div>{" "}
      </WagmiConfig>
    </div>
  );
}

export default App;
