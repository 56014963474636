/** @format */

import React from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import Timer from "../Utils/Timer";

const Hero = () => {
  return (
    <>
      <div className='hero_bg'>
        <div className='container-fluid'>
          <div className='cloud_img'>
            <img src='assets/images/h2.png' className='img1' alt='' />
            <div className='d-flex justify-content-center'>
              <img src='assets/images/h3.png' className='img2' alt='' />
              <img src='assets/images/h4.png' className='img3' alt='' />
            </div>
          </div>
          <div className='image1 d-flex justify-content-center flex-column  align-items-center'>
            <h1 className=' z-2'>MEME FARMS</h1>
            <h5 className=' z-2'>ONLY MEME TOKEN TO DOMINATE THEM ALL. </h5>
          </div>

          <div className='my-2 d-flex justify-content-center align-items-center'>
            <div className='main_card'>
              <div className='d-flex flex-column justify-content-between align-items-center'>
                <span className='span1'>🚀 MEME FARMS IS NOW LIVE 🚀</span>
                <span className='span2'>ON UNISWAP</span>
                {/* <Timer /> */}
                <div>
                  <a
                    href='https://app.uniswap.org/swap?outputCurrency=0x20f1ba5eb018a797e4a45f68cb53258b34ff48c9'
                    target='_blank'
                    rel='noopener noreferrer'>
                    {" "}
                    <img
                      src='assets/images/Uniswap_Logo.png'
                      width={150}
                      alt=''
                    />
                  </a>{" "}
                  &nbsp;
                  &nbsp;
                  <a
                    href='https://www.dextools.io/app/en/ether/pair-explorer/0x6e6377f61cd299320c5e223ea3184b6bf5416bfd'
                    target='_blank'
                    rel='noopener noreferrer'>
                    {" "}
                    <img src='assets/images/dexview.svg' width={140} alt='' />
                  </a>{" "}
                </div>

                {/* <span className='span3'>$MEMEFARMS</span> */}
              </div>
            </div>
          </div>
          <div className='my-5 d-flex justify-content-center align-items-center'>
            <div className='main_card2'>
              <div className='d-flex flex-column justify-content-between align-items-center'>
                <span className='span1'>
                  KEEP A TIGHT GRIP ON YOUR WALLETS.
                </span>
                <span className='span2'>$MEMEFARMS IS ON ITS WAY!</span>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-center '>
            <AiOutlineArrowDown size={35} color='#fff' className='botm_icon ' />
          </div>
        </div>
        <div className='bottom_img  position-absolute bottom-0'>
          <img
            src='assets/images/coin1.png'
            className='img-fluid st_1'
            alt=''
          />
          <img
            src='assets/images/coin1.png'
            className='img-fluid st_2'
            alt=''
          />
          <img
            src='assets/images/coin1.png'
            className='img-fluid st_3'
            alt=''
          />
        </div>
      </div>
    </>
  );
};

export default Hero;
