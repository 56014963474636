/** @format */

import React from "react";

const Tokenomics = () => {
  return (
    <>
      <div className='tokenomic_sec pt-5' id='tokenomics'>
        <div className='container'>
          <h3>Tokenomics?</h3>
          <p>
            Total supply: <span>69,000,000,000</span>
          </p>
          {/* <p>
            fdv: <span>us$69,000,000</span>
          </p> */}
          <p>
            price per $MEMEFARMS: <span>$0.001 ?</span>
          </p>
          {/* <p className='my-4'>
            fire sale supply:{" "}
            <span>7,590,000,000 (11% of total supply), specifically:</span>
          </p> */}
          <br />
          <span>
            . day 1 unlock: 1,897,500,000 (2.75% of total supply){" "}
          </span>{" "}
          <br />
          <span>
            . the remaining amount unlocked daily over the next 18 months
          </span>
          <br />
          <span>.No private sale</span>
          <br />
          <span>
            . a detailed breakdown of the tokenomics will be shared before the
            fairlaunch
          </span>
          <br />
          <div className='d-flex justify-content-end py-5'>
            <img src='assets/images/t1.gif' className='img-fluid' alt='' />
          </div>
        </div>
      </div>
    </>
  );
};

export default Tokenomics;
