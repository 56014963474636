/** @format */

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBhkATO52XWPn4Mxp4RErMU6X6OEjh3UQk",
  authDomain: "memefarms-ccf4e.firebaseapp.com",
  projectId: "memefarms-ccf4e",
  storageBucket: "memefarms-ccf4e.appspot.com",
  messagingSenderId: "785519550212",
  appId: "1:785519550212:web:a75abf4356183046613a51",
  measurementId: "G-R3W78CDC9X"
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);
