/** @format */

import React from "react";
import Hero from "../components/Elements/Hero";
import Sections from "../components/Elements/Sections";
import Tokenomics from "../components/Elements/Tokenomics";
import Waitlist from "../components/Elements/Waitlist";
import Header from "../components/Layouts/Header";
import Footer from "../components/Layouts/Footer";

const Home = () => {
  
  return (
    <>
      
      <Header classN='text-light ' filter='nav-img ' />
      <Hero />
      <Sections />
      <Tokenomics />
      <Waitlist />
      <Footer />
    </>
  );
};

export default Home;
