/** @format */

import React from "react";
import Header2 from "../components/Layouts/Header2";

const WhitePaper = () => {
  return (
    <div className='container-fluid whitepaper '>
      <Header2 className='text-dark ' />
      <div className='container'>
        <div className='w-75 m-auto '>
          {/* <h2></h2> */}
          <br />

          <h4>$MEMEFARMS</h4>

          <p>
            MemeFarm is a digital token built on the Ethereum
            blockchain.$MEMEFARMS lacks any functionalities, utility, or
            inherent value, and it does not guarantee or imply any financial
            returns, profits, interests, or dividends.
          </p>
          <p>
            $MEMEFARMS the most memeable memecoin in existence. The others have
            had their day, it’s time for MemeFarm to take reign.
          </p>
          <p>
            <strong>
              $MEMEFARMS has no practical utility and is purely meant for
              Entertainment.
            </strong>
          </p>
          <ul>
            <li>
              $MEMEFARMS is not intended to be used as a form of money for
              buying things or paying off debts.
            </li>
            <li>
              $MEMEFARM isn't created for people to use it as a way to buy
              anything.
            </li>
            <li>
              $MEMEFARMS isn't a digital version of any type of investment or
              financial product like stocks, bonds, or commodities, anywhere in
              the world.
            </li>
            <li>
              Allowing holders to trade $MEMEFARMS for goods or services is not
              permitted.
            </li>
            <li>
              It does not signify any rights to fees, dividends, income,
              profits, or investment gains.
            </li>
            <li>
              It doesn't give any special privileges in contracts or any other
              arrangements that aim to make a profit or prevent a loss.
            </li>
          </ul>
          <br />
          <h4>DISCLAIMERS</h4>
          <p>
            This document and the websites are meant for providing general
            information only and do not serve as an official offer for any
            investments or digital tokens. The information provided may not
            cover everything and does not create any legal obligations. We
            cannot guarantee the accuracy or completeness of the information
            provided.
          </p>
          <p>
            You shouldn't consider anything in the Whitepaper or the Websites as
            a guarantee or promise about how well the $MEMEFARM will perform in
            the future
          </p>
          <p>
            No official organization has officially reviewed or endorsed any of
            the information provided in the Whitepaper or on the Websites. This
            also means that no such actions or assurances have been taken in
            accordance with the laws or regulations of any jurisdiction. Just
            because the Whitepaper or the Websites are published, distributed,
            or shared does not mean that the relevant laws and regulations have
            been followed.
          </p>
          <br />
          <h4>EMPOWERING THE COMMUNITY;</h4>
          <p>
            One of the main focuses of $MEMEFARMS is its focus on social media
            and community engagement. The creators of the coin believe that
            building a strong community is key to the success of any
            cryptocurrency project.
          </p>
          <p>
            This $MEMEFARM is a unique and groundbreaking initiative in the
            world of cryptocurrency. By eliminating taxes, team tokens, and
            private sales, we are reshaping the landscape of digital assets.
          </p>
          <p>
            We firmly believe in a community-driven approach, which is why the
            project's contract has been renounced, placing the power directly in
            the hands of our community members. With no hidden agendas or
            reserved tokens, every participant has an equal opportunity to
            contribute and benefit from the project's success.
          </p>
          <p>
            This transparent and egalitarian model ensures that our community's
            interests are at the forefront of every decision we make. We are
            dedicated to fostering a fair and inclusive environment where trust
            and collaboration reign supreme, setting a new standard for what a
            truly community-driven project can achieve.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhitePaper;
