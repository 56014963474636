/** @format */

import React, { useState, useEffect } from "react";
import Header1 from "../components/Layouts/Header1";
import {
  getAuth,
  onAuthStateChanged,
  TwitterAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { firebase } from "../components/Utils/Firebase";
import { AiOutlineRight, AiTwotoneLock } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { RiLogoutCircleRFill } from "react-icons/ri";
import CountUp from "react-countup";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount } from "wagmi";

const Farming = () => {
  const { isConnected } = useAccount();
  const [twitter_id, setTwitterId] = useState("");
  const { open } = useWeb3Modal();

  const auth = getAuth(firebase);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const [loading, setLoading] = useState(false);
  const [rewardBtn, setRewardBtn] = useState(true);
  const [user, setUser] = useState(null);
  const [score, setScore] = useState(null);
  const handleClose = () => setShowModal(false);
  const handleClose1 = () => setShowModal1(false);
  const handleClose2 = () => setShowModal2(false);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const getRef = () => {
    const ref = query.get("ref");
    return ref;
  };
  const ref = getRef();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, authUser => {
      if (authUser) {
        setTwitterId(authUser?.uid);
        console.log("user Login Id", twitter_id);
        if (twitter_id !== "") {
          const userName = authUser?.displayName;
          axios
            .post("https://memecoinapi.onrender.com/api/linkx", {
              twitter_id,
              userName,
            })
            .then(res => {
              console.log(res?.data?.message);
              setScore(res?.data?.data);
              setLoading(false);
              if (ref !== "" && twitter_id !== "") {
                referred();
              } else {
                console.log("ref not  get and tw id");
              }
            })
            .catch(error => {
              setLoading(false);
              console.log(error);
            }); 
          return;
        }

        setUser(authUser);
      } else {
        // User is signed out.
        setLoading(false);
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth, twitter_id]);
  // console.log(user?.providerData[0].uid);
  // const twitterUserId = { twitter_id: user?.providerData[0].uid };
  const twitterLogin = () => {
    setLoading(true);
    const provider = new TwitterAuthProvider();
    signInWithPopup(auth, provider)
      .then(result => {
        const credentials = TwitterAuthProvider.credentialFromResult(result);
        const token = credentials.accessToken;
        const secret = credentials.secret;
        // console.log(token, secret);
        // The signed-in user info.
        const userLogin = result.user;
        // console.log("user Login Id", userLogin?.uid);
        setUser(userLogin);
        //   axios
        //     .post("https://memecoinapi.onrender.com/api/linkx", { twitter_id })
        //     .then(res => {
        //       console.log(res?.data);
        //       setScore(res?.data?.data);

        //       setLoading(false);
        //       handleClose();
        //     })
        //     .catch(error => {
        //       setLoading(false);
        //       // console.log(error);
        //     });
      })
      .catch(error => {
        const errorMessage = error.message;
        setLoading(false);
        console.log(errorMessage);

        const email = error.customData;
        console.log(email);

        const credential = TwitterAuthProvider.credentialFromError(error);
        console.log(credential);
      });
  };
  const Logout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch(error => {
        // An error happened.
      });
  };

  const [data, setData] = useState({
    paraText: "",
    tweetText: "",
    btn1: "",
    btn1Link: "",
    btn2: "",
    btn2Fun: null,
  });
  const [data1, setData1] = useState({
    paraText: "",
    tweetText: "",
    btn1: "",
    btn1Fun: null,
    btn2Fun: null,
  });

  const [data2, setData2] = useState({
    paraText: "",
    tweetText: "",
    btn1: "",
    btn1Fun: null,
  });
  //  ========== shared ============
  const tell = () => {
    setLoading(true);
    console.log(twitter_id);
    if (twitter_id) {
      axios
        .put("https://memecoinapi.onrender.com/api/tell", { twitter_id })
        .then(res => {
          console.log(res?.data?.message);
          setScore(res?.data?.data);
          handleClose();
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  //  ========== follow ============

  const follow = () => {
    setLoading(true);
    console.log(twitter_id);
    if (twitter_id) {
      axios
        .put("https://memecoinapi.onrender.com/api/follow", { twitter_id })
        .then(res => {
          console.log(res?.data?.message);

          setScore(res?.data?.data);
          handleClose();
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  //  ========== wallet ============

  const linkWallet = () => {
    setLoading(true);
    console.log(twitter_id);
    if (twitter_id) {
      axios
        .put("https://memecoinapi.onrender.com/api/linkwallet", { twitter_id })
        .then(res => {
          console.log(res?.data?.message);
          setScore(res?.data?.data);
          handleClose1();
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  //  ========== join ============

  const join = () => {
    setLoading(true);
    console.log(twitter_id);
    if (twitter_id) {
      axios
        .put("https://memecoinapi.onrender.com/api/join", { twitter_id })
        .then(res => {
          console.log(res?.data?.message);
          setScore(res?.data?.data);
          handleClose();
          handleClose2();
          handleClose1();
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  //  ========== referred ============

  const referred = () => {
    setLoading(true);
    if (ref !== "" && twitter_id !== "") {
      console.log("ref =>", ref);
      console.log("tw =>", twitter_id);
      const referredBy = ref;
      axios
        .put("https://memecoinapi.onrender.com/api/referred", {
          twitter_id,
          referredBy,
        })
        .then(res => {
          console.log(res?.data.message);
          setScore(res?.data?.data);

          handleClose();
          handleClose2();
          handleClose1();
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (ref !== "" && twitter_id !== "") {
      referred();
    } else {
      console.log("ref not  get and tw id");
    }
  }, [twitter_id]);

  return (
    <div className=' container-fluid  mt-5 pb-5'>
      <div className='main'></div>
      <Header1 />
      <div className='container pt-5'>
        <div className='farm-box m-auto position-relative '>
          {user?.uid ? (
            <div>
              <button
                className='position-absolute end-0 top-0 logout'
                onClick={Logout}>
                <RiLogoutCircleRFill className='mx-2' />
                Logout
              </button>
              <div
                className='position-absolute start-0 top-0 text-light user p-2'
                onClick={referred}>
                <img
                  src={user?.photoURL}
                  alt=''
                  width={24}
                  height={24}
                  className='mx-2 rounded-circle '
                />
                {user?.displayName}
              </div>
              <h5>MEMEPOINTS</h5>
              <h1>
                {" "}
                <CountUp delay={2} start={0} end={score?.score} />
                🌽
              </h1>
              <div className='Referred m-auto mb-3'>
                {score?.referredCont - 1} Referred
              </div>
              <ol className='w-50 m-auto'>
                <li>COMPLETE QUESTS</li>
                <li>COLLECT MEMEPOINTS</li>
                <li>PROFIT! HARVEST!</li>
              </ol>
              <p className='mt-2 boxx text-center text-warning'>
                Claim #MemeFarms token on 3rd Nov 2023
              </p>

              {/* ======= start tell ======== */}
              <div
                className={` quests-box m-auto  row  ${score?.tell && "done"}`}
                onClick={() => {
                  !score?.tell && setShowModal(true);
                  setData({
                    paraText:
                      "It may take a minute for us to find  your post. Ask Elon to speed it up.",
                    tweetText: `Hi, my name is <span>@${user?.displayName}</span>, and I’m a farmer at at <span>@Meme_Farms</span>.
                      <br />
                      <br />
                      I promise to always work hard to take care of Earnings  <span>#MEMEFARMSPOINTS</span> whenever I can.
                     <br />
                     <br />
                    <span> #Memefarms #Memecoin #Memeland</span>`,
                    btn1: `SHARE PUBLICLY ON &nbsp;
                    <img src='assets/images/Xlogo.png'alt='' width="20" style="filter: invert(1)" />`,
                    btn1Link: `http://twitter.com/share?text=Hi, my name is @${user?.displayName}, and I’m a farmer  at @Meme_Farms I promise to always work hard to take care of Earnings,   whenever I can&url=https://memefarms.org/farming &hashtags=Memefarms,Memecoin,Memeland,MEMEFARMSPOINTS`,
                    btn2Fun: tell,
                  });
                }}>
                <div className='col-2 position-relative'>
                  <p className='fs-2 text-center'>🌽</p>
                  <div className='point'>100</div>
                </div>
                <div className='col-8 '>
                  <p>Tell the world!</p>
                </div>
                <div className='col-1 '>
                  <p>
                    {score?.tell ? (
                      <IoCheckmarkDoneCircleSharp className='text-light' />
                    ) : (
                      <AiOutlineRight className='text-light' />
                    )}
                  </p>
                </div>
              </div>
              {/* ======= start twitter follow ======== */}
              <div
                className={` quests-box m-auto  row mt-3 ${
                  score?.follow && "done"
                }`}
                onClick={() => {
                  !score?.follow && setShowModal(true);
                  setData({
                    paraText:
                      "If you are not following Meme Farms, why are you here?",
                    tweetText: `<span>@Meme_Farms</span>`,
                    btn1: `Follow &nbsp;
                    <img src='assets/images/Xlogo.png'alt='' width="20" style="filter: invert(1)" />`,
                    btn1Link: `https://twitter.com/intent/user?screen_name=Meme_Farms`,
                    btn2Fun: follow,
                  });
                }}>
                <div className='col-2 position-relative'>
                  <p className='fs-2 text-center'>🌽</p>
                  <div className='point'>50</div>
                </div>
                <div className='col-8 '>
                  <p>
                    Follow "
                    <img
                      src='assets/images/Xlogo.png'
                      alt=''
                      width='20'
                      style={{ filter: "invert(1)" }}
                    />
                    " @Meme_Farms
                  </p>
                </div>
                <div className='col-1  '>
                  <p>
                    {score?.follow ? (
                      <IoCheckmarkDoneCircleSharp className='text-light' />
                    ) : (
                      <AiOutlineRight className='text-light' />
                    )}
                  </p>
                </div>
              </div>

              {/* ======= start join tg ======== */}
              <div
                className={` quests-box m-auto  row mt-3 ${
                  score?.join && "done"
                }`}
                onClick={() => {
                  !score?.join && setShowModal(true);
                  setData({
                    paraText:
                      "Join official telegram community for the latest updates",
                    tweetText: `Join  <span>@Meme_Farms</span>`,
                    btn1: `Join Telegram`,
                    btn1Link: `https://t.me/Meme_Farms`,
                    btn2Fun: join,
                  });
                }}>
                <div className='col-2 position-relative'>
                  <p className='fs-2 text-center'>🌽</p>
                  <div className='point'>50</div>
                </div>
                <div className='col-8 '>
                  <p>Join @Meme_Farms community</p>
                </div>
                <div className='col-1  '>
                  <p>
                    {score?.join ? (
                      <IoCheckmarkDoneCircleSharp className='text-light' />
                    ) : (
                      <AiOutlineRight className='text-light' />
                    )}
                  </p>
                </div>
              </div>
              {/* ======= start referred ======== */}
              <div
                className={` quests-box m-auto  row mt-3 ${
                  score?.referred && ""
                }`}
                onClick={() => {
                  setShowModal2(true);
                  setData2({
                    paraText:
                      "Use the unique referral link provided and earn extra points with $MEMEFARMS! Don't miss out on this opportunity to boost your rewards.",
                    tweetText: `https://memefarms.org/farming?ref=${twitter_id}`,
                    btn1: `Referral link`,
                    btn1Link: `https://memefarms.org/farming?ref=${twitter_id}`,
                  });
                }}>
                <div className='col-2 position-relative'>
                  <p className='fs-2 text-center'>🌽</p>
                  <div className='point'>30</div>
                </div>
                <div className='col-8 '>
                  <p>Referred by friends?</p>
                </div>
                <div className='col-1  '>
                  <p>
                    {score?.referred ? (
                      <IoCheckmarkDoneCircleSharp className='text-light' />
                    ) : (
                      <AiOutlineRight className='text-light' />
                    )}
                  </p>
                </div>
              </div>

              {/* ======= start link wallet ======== */}
              <div
                className={` quests-box m-auto  row mt-3 ${
                  score?.linkWallet && "done"
                }`}
                onClick={() => {
                  !score?.linkWallet && setShowModal1(true);
                  setData1({
                    paraText:
                      "$MemeFarms will be distributed to this wallet depending on your MEMEPOINTS. If you intend to invite friends, family, acquaintances, Satoshi Nakamoto, or even Gary Gensler, ensure that this wallet holds the MVP/Captainz/Potatoz you utilize.",
                    tweetText: `Please ensure that you connect the accurate NFT-holding wallet. Once linked, this cannot be altered. We also accept delegated wallets.`,
                    btn1: `LINK WALLET `,
                    btn1Fun: open,
                    btn2Fun: linkWallet,
                  });
                }}>
                <div className='col-2 position-relative'>
                  <p className='fs-2 text-center'>🌽</p>
                  <div className='point'>50</div>
                </div>
                <div className='col-8 '>
                  <p>Link your wallet</p>
                </div>
                <div className='col-1  '>
                  <p>
                    {score?.linkWallet ? (
                      <IoCheckmarkDoneCircleSharp className='text-light' />
                    ) : (
                      <AiOutlineRight className='text-light' />
                    )}
                  </p>
                </div>
              </div>

              {/* ======= start lock ======== */}
              <div className={` quests-box m-auto  row mt-3 ${"done"}`}>
                <div className='col-2 position-relative'>
                  <p className='fs-2 text-center'>🌽</p>
                  <div className='point'>???</div>
                </div>
                <div className='col-8 '>
                  <p>Locked</p>
                </div>
                <div className='col-1  '>
                  <p>
                    <AiTwotoneLock className='text-light' />
                  </p>
                </div>
              </div>

              {/* ======= start Link profile======== */}
              <div
                className={` quests-box m-auto  row mt-3 ${
                  score?.linkX && "done"
                }`}>
                <div className='col-2 position-relative'>
                  <p className='fs-2 text-center'>🌽</p>
                  <div className='point'>300</div>
                </div>
                <div className='col-8 '>
                  <p>Link your X profile</p>
                </div>
                <div className='col-1  '>
                  <p>
                    {score?.linkX ? (
                      <IoCheckmarkDoneCircleSharp className='text-light' />
                    ) : (
                      <AiOutlineRight className='text-light' />
                    )}
                  </p>
                </div>
              </div>

              <p className='mt-4 text-center boxx'>... and more</p>
            </div>
          ) : (
            <div>
              <img
                src='assets/images/farming.png'
                className='w-100 mb-5'
                alt=''
              />
              <h4>MEME FARMING</h4>
              <ol className='w-50 m-auto pt-3 mb-4'>
                <li>COMPLETE QUESTS</li>
                <li>COLLECT MEMEPOINTS</li>
                <li>PROFIT! HARVEST!</li>
              </ol>
              <button onClick={twitterLogin} className='btn' disabled={loading}>
                {loading ? (
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                ) : (
                  "Login with"
                )}
                &nbsp;
                <img
                  src='assets/images/Xlogo.png'
                  className='nav-img'
                  style={{ width: 22, display: "inline-block" }}
                  alt=''
                />
              </button>
            </div>
          )}
        </div>
        <p className='text-light mt-3 text-center opacity-75 '>
          BOTS AND SUSPICIOUS ACTIONS WILL BE FILTERED WITHOUT PRIOR NOTICE.
        </p>
      </div>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <div className='header-m'>
            <h2>QUEST</h2>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p
            className='mt-4'
            dangerouslySetInnerHTML={{ __html: data?.paraText }}
          />
          <div className='tweet mt-3 mb-3'>
            <p dangerouslySetInnerHTML={{ __html: data?.tweetText }} />
          </div>
          <a href={data?.btn1Link} target='_blank' rel='noopener noreferrer'>
            <button
              onClick={() => setRewardBtn(false)}
              className='mb-3 fill '
              dangerouslySetInnerHTML={{
                __html: loading
                  ? `<div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              `
                  : data?.btn1,
              }}
            />
          </a>

          <button
            disabled={rewardBtn}
            onClick={data?.btn2Fun}
            className='mb-3 outline btn'>
            CLAIM REWARD
          </button>
        </Modal.Body>
      </Modal>

      <Modal show={showModal2} onHide={handleClose2} centered>
        <Modal.Header closeButton>
          <div className='header-m'>
            <h2>REFERRED</h2>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p
            className='mt-4'
            dangerouslySetInnerHTML={{ __html: data2?.paraText }}
          />
          <div className='tweet mt-3 mb-3'>
            <p dangerouslySetInnerHTML={{ __html: data2?.tweetText }} />
          </div>
          <a href={data2?.btn1Link} target='_blank' rel='noopener noreferrer'>
            <button
              className='mb-3 fill '
              dangerouslySetInnerHTML={{
                __html: loading
                  ? `<div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              `
                  : data2?.btn1,
              }}
            />
          </a>
          {/* {score?.referredCont > 1 && (
            <button onClick={referred} className='mb-3 outline btn'>
              CLAIM REWARD
            </button>
          )} */}
        </Modal.Body>
      </Modal>

      <Modal show={showModal1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <div className='header-m'>
            <h2>QUEST</h2>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p
            className='mt-4'
            dangerouslySetInnerHTML={{ __html: data1?.paraText }}
          />
          <div className='tweet mt-3 mb-3'>
            <p dangerouslySetInnerHTML={{ __html: data1?.tweetText }} />
          </div>
          {!isConnected && (
            <button
              onClick={() => {
                data1?.btn1Fun();
                setRewardBtn(false);
              }}
              className='mb-3 fill '
              dangerouslySetInnerHTML={{
                __html: loading
                  ? `<div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              `
                  : data1?.btn1,
              }}
            />
          )}
          {isConnected && (
            <button onClick={data1?.btn2Fun} className='mb-3 outline btn'>
              CLAIM REWARD
            </button>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Farming;
