/** @format */

import { FaTelegram } from "react-icons/fa";
import { Link } from "react-router-dom";
import React, { useRef, useState, useEffect } from "react";
import LoadingBar from "react-top-loading-bar";
const Header = ({ classN, filter }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const scrollProgress = () => {
      const scrollPx = document.documentElement.scrollTop;
      const winHeightPx =
        document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrolled = `${(scrollPx / winHeightPx) * 100}`;
      setProgress(scrolled);
    };

    window.addEventListener('scroll', scrollProgress);

    return () => {
      window.removeEventListener('scroll', scrollProgress);
    };
  }, []);
  return (
    <>
      <nav
        className='navbar navbar-expand-lg fixed-top '
        style={{ zIndex: "1" }}>
        <div className='container'>
          <Link className='navbar-brand' to='/'>
            <img
              src='assets/images/logo.png'
              className='img-fluid '
              id='slidecaption'
              alt='logo'
            />
          </Link>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon' />
          </button>
          <div className='collapse navbar-collapse' id='navbarText'>
            <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
              <li className='nav-item '>
                <a
                  className={`nav-link ${classN}`}
                  aria-current='page'
                  href='#tokenomics'>
                  Tokenomics
                </a>
              </li>
              <li className='nav-item'>
                <a className={`nav-link ${classN}`} href='#tiimeline'>
                  Timeline
                </a>
              </li>
              <li className='nav-item'>
                <Link className={`nav-link ${classN}`} to='/farming'>
                  FARMING 🧑‍🌾
                </Link>
              </li>
              <li className='nav-item'>
                <Link  className={`nav-link ${classN}`} to='/whitepaper'>
                  Whitepaper
                </Link>
              </li>
            </ul>

            <span className='navbar-text  '>
              <a
                href='https://twitter.com/Meme_Farms'
                target='_blank'
                rel='noopener noreferrer'>
                {" "}
                <img
                  src='assets/images/Xlogo.png'
                  className={`img-fluid  ${filter}`}
                  width={30}
                  alt=''
                />
              </a>
              <a
                href='https://t.me/Meme_Farms'
                target='_blank'
                rel='noopener noreferrer'>
                <FaTelegram className={`mx-4  fs-1  ${classN}`} />
              </a>
            </span>
          </div>
        </div>
      </nav>
      <LoadingBar
        color='#fff'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
    </>
  );
};

export default Header;
