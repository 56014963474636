/** @format */

import React from "react";
import { FaDiscord, FaTelegram } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { Link } from "react-router-dom";

const Footer = () => {
  
  return (
    <>
      <footer
        className='footer text-center text-lg-start text-white'
        style={{ backgroundColor: "#18202e" }}>
        <div className='container text-center'>
          <div className='row'>
            <div className='col-sm-4'>
              <Link to='#'>MEME FARMS</Link>
            </div>
            <div className='col-sm-4 '>
              <img
                src='assets/images/logo.png'
                className='img-fluid w-50'
                id='slidecaption'
                alt=''
              />
            </div>
            <div className='col-sm-4 '>
              <a href="https://twitter.com/Meme_Farms" target="_blank" rel="noopener noreferrer"><BsTwitter className='mx-4 icon' size={25} /></a>
              <a href="https://t.me/Meme_Farms" target="_blank" rel="noopener noreferrer"><FaTelegram className='mx-4 icon' size={25} /></a>
              <a href="https://discord.gg/kJEAbPznC" target="_blank" rel="noopener noreferrer"><FaDiscord className='mx-4 icon' size={25} /></a>
            </div>
          </div>
          <div className='d-flex justify-content-center align-items-center mt-3'>
            <div>
              <p>©️ 2023 MEMEFARMS.</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
