/** @format */

import React from "react";
import { AiOutlineArrowDown } from "react-icons/ai";

const Waitlist = () => {
  return (
    <>
      <div className='waitlist_sec' id='tiimeline'>
        <div className='container'>
          <div className='row pb-5'>
            <div className='col-md-6 px-4 mt-5'>
              <div className='grid_left'>
                <h3>
                  it's always <br /> $MEMEFARMS season
                </h3>
                <p className='text-end'>
                  HOLD THE $MEMEFARM HOLDS THE UNIVERSE
                </p>
              </div>
            </div>
            <div className='col-md-6 px-4'>
              <div className='grid_right'>
                {/* <h3>FAIRLAUNCH</h3> */}
                {/* <p>JUN 9, 2023</p> */}

                {/* <ul>
                  <span className='mb-4 fw-bold '>
                    HOW TO participants in FAIRLAUNCH?
                  </span>
                  <li>Access the Pinksale link (Pinksale.finance)</li>
                  <li>Connect your Web 3 Wallet.</li>
                  <li>Enter the desired purchase amount.</li>
                  <li>Click on BUY now.</li>
                  <p className='mt-3'>
                    A WISE MAN ONCE SAID: "INVESTING IN PROJECTS EARLY ON OFTEN LEADS TO BETTER CHANCES OF REAPING GOOD RETURNS.".
                  </p>
                </ul> */}

       
                <hr />
                <h3>LISTING</h3>
                <p>Soon</p>
                <p>ON FIRE, YO</p>
                <ul>
                  <span>
                    <strong className='text-center'>How To TRADE</strong>
                  </span>{" "}
                  <br />
                  <span className='mb-4 fw-bold '>- Create a wallet</span>
                  <p>
                    Get Metamask, or any preferred wallet, for free from the
                    Apple App Store or Google Play Store. For desktop users,
                    acquire the Google Chrome extension by visiting metamask.io.
                  </p>
                  <span className='mb-4 fw-bold '>- Get some ETH</span>
                  <p>
                    Ensure your wallet holds ETH to transition to $MemeFarms. If
                    you lack ETH, you can purchase it directly on Metamask,
                    transfer from an alternate wallet, or buy from another
                    exchange and send it to your wallet.
                  </p>
                  <span className='mb-4 fw-bold '>- Go to Uniswap</span>
                  <p>
                    Link to Uniswap. Visit app.uniswap.org on your web browser
                    or the browser within the Metamask app. Connect your wallet.
                    Paste the $MemeFarms token address into Uniswap, choose
                    MemeFarms, and confirm. When prompted by Metamask for a
                    wallet signature, sign.
                  </p>
                  <span className='mb-4 fw-bold '>
                    - Swap ETH for MEMEFARMS
                  </span>
                  <p>
                    Exchange ETH for $MemeFarm. Our platform imposes no taxes,
                    so you needn't fret about purchasing with a specific
                    slippage, although using slippage might be necessary during
                    periods of market volatility.
                  </p>
                </ul>

                {/* <ul>
                  <span className='mb-4 fw-bold '>FULL AND SUPER CREWS</span>
                  <li>
                    FULL CREWS: YOU'RE RICH SO YOU GET 1 EXTRA LOT PER FULL
                    CREW.
                  </li>
                  <li>SUPER CREWS: YOU'RE ULTRA RICH SO YOU ALSO GET,</li>
                  <ul>
                    <li>EXTRA 12 LOTS PER SUPER CREW GUARANTEED</li>
                    <li>ACCESS TO PRIVATE SALE</li>
                  </ul>
                  <li>MUST BE QUESTING FULL AND SUPER CREWS TO BE ELIGIBLE.</li>
                </ul> */}

                {/* <ul>
                  <span>WAITLIST CODE USERS</span>
                  <p>
                    WALLETS THAT HAVE SUCCESSFULLY CLAIMED THE WAITLIST CODE
                    WILL HAVE AN ALLOCATION OF 1 LOT. REMAINING SUPPLIES FROM
                    THE ALLOWLIST WILL GO TO LUCKY WAITLIST DEPOSIT WALLETS,
                    CHOSEN AT RANDOM.
                  </p>
                </ul>
                <ul>
                  <span>HOW WILL THE FIRE SALE WORK?</span>
                  <li>
                    ALLOWLIST IS GUARANTEED. YOU WILL BE ALLOCATED THE LOT IF
                    YOU PAY FOR THE LOT DURING THE FIRE SALE. YOU DO NOT NEED TO
                    RUSH.
                  </li>
                  <li>
                    WAITLIST IS NON-GUARANTEED. ITS PRIORITY IS BELOW THE
                    ALLOWLIST. A RAFFLE WILL BE HELD AT THE END OF THE FIRE SALE
                    TO DETERMINE THE ALLOTMENT. IF YOU PAY FOR THE LOT DURING
                    THE FIRST HOUR OF THE FIRE SALE, YOU GET A 6.9X BOOST IN THE
                    RAFFLE.
                  </li>
                  <li>
                    $MEME IN FIRE SALE WILL BE CLAIMABLE BY THE CONTRIBUTING
                    WALLET AND IS NOT ATTACHED TO NFTS.
                  </li>
                  <li>
                    THE ALLOTMENT RESULTS WILL BE ANNOUNCED WITHIN 48 HOURS
                    AFTER THE FIRE SALE.
                  </li>
                  <li>
                    ALL UNALLOCATED AMOUNTS WILL BE REFUNDED WITHIN 48 HOURS
                    AFTER THE FIRE SALE.
                  </li>
                  <li>
                    THE PRICE PER LOT IS THE SAME FOR EVERYONE AT 300 USD. THE
                    ETH REQUIRED WILL BE ANNOUNCED 2 HOURS BEFORE FIRE SALE.
                  </li>
                  <li>EACH LOT IS EQUIVALENT TO $MEME 300,000.</li>
                </ul> */}
                <hr />
                <h3>STAKING</h3>
                <p>AFTER LAUNCH</p>
                <p>who says there is no extra rewards?</p>
                <ul>
                  <p className='mt-3'>
                    -. . ...- . .-. / --. --- -. -. .- / --. .. ...- . / -.--
                    --- ..- / ..- .--. / -. . ...- . .-. / --. --- -. -. .- /
                    .-.. . - / -.-- --- ..- / -.. --- .-- -. / -. . ...- . .-. /
                    --. --- -. -. .- / .-. ..- -. / .- .-. --- ..- -. -.. / .-
                    -. -.. / -.. . ... . .-. - / -.-- --- ..- / -. . ...- . .-.
                    / --. --- -. -. .- / -- .- -.- . / -.-- --- ..- / -.-. .-.
                    -.-- / -. . ...- . .-. / --. --- -. -. .- / ... .- -.-- /
                    --. --- --- -.. -... -.-- . / -. . ...- . .-. / --. --- -.
                    -. .- / - . .-.. .-.. / .- / .-.. .. . / .- -. -.. / ....
                    ..- .-. - / -.-- --- ..-
                  </p>
                </ul>
                <hr />
                <h3>TOKEN(S) LAUNCH</h3>
                <p>SOON</p>
                <p>LFG!</p>
                <ul>
                  <p>
                    -. . ...- . .-. / --. --- -. -. .- / --. .. ...- . / -.--
                    --- ..- / ..- .--. / -. . ...- . .-. / --. --- -. -. .- /
                    .-.. . - / -.-- --- ..- / -.. --- .-- -. / -. . ...- . .-. /
                    --. --- -. -. .- / .-. ..- -. / .- .-. --- ..- -. -.. / .-
                    -. -.. / -.. . ... . .-. - / -.-- --- ..- / -. . ...- . .-.
                    / --. --- -. -. .- / -- .- -.- . / -.-- --- ..- / -.-. .-.
                    -.-- / -. . ...- . .-. / --. --- -. -. .- / ... .- -.-- /
                    --. --- --- -.. -... -.-- . / -. . ...- . .-. / --. --- -.
                    -. .- / - . .-.. .-.. / .- / .-.. .. . / .- -. -.. / ....
                    ..- .-. - / -.-- --- ..-
                  </p>
                </ul>
              </div>
            </div>
          </div>

          <div className='my-5 d-flex justify-content-center align-items-center'>
            <div className='main_card2'>
              <div className='d-flex flex-column justify-content-between align-items-center'>
                <span className='span1'>
                  We do not require an excess of meme tokens.
                </span>
                <span className='span2'>We simply need one meme token,</span>
                <span className='span3'>known as $MemeFarms.</span>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-center'>
            <AiOutlineArrowDown size={35} className='botm_icon' />
          </div>
          <div className='bottom_img d-flex justify-content-center '>
            <img
              src='assets/images/footer_gif.gif'
              className='img-fluid'
              alt=''
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Waitlist;
