/** @format */

import React from "react";
import { FaTelegram } from "react-icons/fa";
import { Link } from "react-router-dom";

const Header1 = () => {
  return (
    <>
      <nav className='navbar navbar-expand-lg fixed-top '>
        <div className='container'>
          <div className='navbar-brand text-white'>
          🧑‍🌾 MEME FARMING
          </div>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon' />
          </button>
          <div className='collapse navbar-collapse' id='navbarText'>
            <ul className='navbar-nav me-auto mb-2 mb-lg-0'></ul>
            <span className='navbar-text  '>
              <Link to='/'>
                {" "}
                <img
                  src='assets/images/logo.png'
                  className='img-fluid  mx-3'
                  width={35}
                  alt=''
                />
              </Link>
              <a
                href='https://twitter.com/Meme_Farms'
                target='_blank'
                rel='noopener noreferrer'>
                {" "}
                <img
                  src='assets/images/Xlogo.png'
                  className='img-fluid nav-img  '
                  width={30}
                  alt=''
                />
              </a>
              <a
                href='https://t.me/Meme_Farms'
                target='_blank'
                rel='noopener noreferrer'>
                <FaTelegram className='mx-4  fs-1 text-light' />
              </a>
            </span>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header1;
