/** @format */

import React from "react";
import { Parallax } from "react-scroll-parallax";
const Sections = () => {

  return (
    <>
      <div className='section_bg w-100 '>
        <div className='container'>
          <div className='main'></div>
          <div>
            <Parallax scale={[1, 1.4, "easeInQuad"]} speed={-10}>
              <img src='assets/images/token.png' className='w-100' alt='' />
            </Parallax>
          </div>
          <h2>MEME FARMS</h2>

          <p>
            $MEMEFARMS EXISTS PURELY AS A MEME TOKEN, <br /> WITH NO PRACTICAL
            USE, NO PLANNED COURSE OF ACTION, <br /> AND NO COMMITMENTS. THERE
            IS NO ANTICIPATION OF FINANCIAL GAINS. 😉
          </p>
        </div>
     
      </div>
    </>
  );
};

export default Sections;
